<template>
  <ConfirmDialog
    :value="value"
    positiveButtonText="Ok"
    negativeButtonText=""
    title="Uwaga!"
    text="Nieprawidłowy status subskrypcji BooksyBiz. Upewnij się że masz aktywną subskrypcję BooksyBiz."
    :positiveAction="handleOk"
    persistent
  >
  </ConfirmDialog>
</template>

<script>
import BooksyService from "@/services/booksy.service";

export default {
  props: {
    value: {
      type: Boolean|undefined,
    },
  },
  data() {
    return {};
  },
  components: {
    ConfirmDialog: () => import("@/components/popups/ConfirmDialog"),
  },
  methods: {
    handleOk() {
      // Call browser back:
      BooksyService.back();
    },
  },
};
</script>
